import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import get from 'lodash.get';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/intellectual-property/intellectual-property-services.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { INTELLECTUAL_PROPERTY_TESTIMONIALS } from '../data/intellectual-property/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'IP Lawyer | Intellectual Property Legal Services | Pandev Law',
  description: `Pandev Law's intellectual property practice represents clients throughout the United States and worldwide. Click to learn more.`,
  keywords: '',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "IP Lawyer | Intellectual Property Legal Services | Pandev Law",
    "description": "Pandev Law's intellectual property practice represents clients throughout the United States and worldwide. Click to learn more.",
    "url": "https://www.pandevlaw.com/intellectual-property-legal-services/",
    "image": "https://pandevlaw.com/img/hands-writing.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/intellectual-property-legal-services/"
  },
  "headline": "Intellectual Property Legal Services",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function IntellectualProperty({ location, data }) {
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero
        title="Intellectual Property Legal Services"
        img={heroImg}
        imgAlt="Intellectual Property Services">
        <p className="text-lead mb-5 mb-md-6">
          Pandev Law’s intellectual property legal services deliver comprehensive,
          practical legal advice and representation focused on maximizing and protecting
          your intellectual property assets and growing your company’s online presence.
          Clients value our multidisciplinary approach, combining our business transaction
          and intellectual property experience, and our high-quality, creative legal
          solutions in intellectual property transactions. Our intellectual property
          practice represents clients throughout the United States and worldwide. Our
          intellectual property legal services cover a wide range of areas, including:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Intellectual Property Client Reviews"
        testimonials={INTELLECTUAL_PROPERTY_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/intellectual-property/ip-transactions-and-licensing.jpg"
                alt="IP Transactions & Licensing"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">
                IP Transactions &amp; Licensing
              </h2>
              <p className="text-body mb-6">
                Pandev Law’s intellectual property practice has extensive experience
                representing clients in transactions involving their intellectual property
                assets. Our IP lawyers understand that intellectual property is often an
                organization’s most important asset. We counsel clients in licensing,
                joint ventures, development, sales and purchases of intellectual property.
                Pandev Law’s international experience, having worked with technology
                companies on every continent, have made us a leader in cross-border IP
                transactions. Combining our international business transaction and
                intellectual property legal services experience, our attorneys represent
                clients in:
              </p>
              <ul className="list-bullets mb-6">
                <li>IP licensing agreements</li>
                <li>Acquisitions and sales of IP assets</li>
                <li>Contribution of IP in joint ventures</li>
                <li>IP due diligence for financing and investment transactions</li>
                <li>IP securitization and debt transactions</li>
                <li>IP holding company formation</li>
                <li>Offshore IP transactions </li>
                <li>
                  Technology transfer, outsourcing, and other similar IP agreements{' '}
                </li>
              </ul>
              <p className="text-body mb-6">
                Pandev Law’s intellectual property practice counsels entrepreneurs and
                business leaders across a wide range of sectors on how to protect and
                maximize the value of IP assets in business transactions. We help clients
                structure complex multi-party joint ventures and strategic alliances,
                conduct due diligence, and value IP portfolios. We use our lawyers’
                industry knowledge to devise legal solutions tailored to your business
                goals.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/intellectual-property/internet-and-e-commerce.jpg"
                alt="Internet & E-Commerce"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Internet & E-Commerce</h2>
              <p className="text-body mb-6">
                Pandev Law’s intellectual property legal services include advice on
                navigating the complexities of your company’s online presence. If your
                business has a website, a mobile app, podcast, or social media account,
                you could benefit from a comprehensive legal strategy that maximizes your
                return from your online presence while mitigating the risk of legal
                liability. Our attorneys serve as strategic counsel to SaaS companies,
                application service providers, audio and video streaming companies,
                software and application developers, and online merchants. Our
                intellectual property lawyers represent clients in various internet and
                e-commerce matters covering websites, mobile apps, and other digital
                platforms, including:
              </p>
              <ul className="list-bullets mb-6">
                <li>E-commerce and online transactions</li>
                <li>End user license agreements</li>
                <li>Privacy policy and disclaimers</li>
                <li>Terms and conditions / Terms of use / Terms of service</li>
                <li>Copyright policy and acceptable use policy</li>
                <li>Forum community guidelines </li>
                <li>Web hosting and internet access agreements</li>
                <li>Website and mobile app development agreements</li>
                <li>Content licensing agreements</li>
                <li>Click-through agreements</li>
                <li>Website content and clearance</li>
                <li>Social media platform compliance</li>
                <li>Amazon, eBay, and digital marketplace compliance</li>
              </ul>
              <p className="text-body mb-6">
                Pandev Law’s intellectual property legal services lawyers have extensive
                experience in drafting agreements, policies, and disclaimers for websites,
                mobile applications, and other digital platforms. As your committed
                partner, we help you stay compliant with the ever-changing terms and
                conditions of online marketplaces and social media platforms. Our IP
                attorneys craft legal solutions tailored to your online business needs –
                whether your focus is business-to-consumer or business-to-business.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/intellectual-property/website-agreements.jpg"
                alt="Website agreements"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Website agreements</h2>
              <p className="text-body mb-5">
                Website agreements, such as terms of service, privacy policies, and
                license agreements protect you from legal liability and risks posed by
                your website. Activities conducted by visitors on your website trigger
                different state and federal laws and regulations. A legal relationship is
                formed between the website owner and the visitor even if the visitor’s
                identity remains unknown or the visitor never purchases anything on the
                website.
              </p>
              <p className="text-body mb-5">
                Your legal compliance risks are further increased by the global nature of
                the internet with individuals from all corners of the world accessing your
                website, thereby potentially exposing you to the internet laws of various
                jurisdictions worldwide. Website agreements serve the critical role of
                helping you set the terms of your relationship with website visitors and
                thereby allowing you to stay compliant with regulations and to mitigate
                legal risks. Our intellectual property legal services attorneys use their
                years of contract drafting and internet law experience to draft website
                agreements. Our website agreement services include:
              </p>
              <div className="accordion" id="websiteAgreements">
                <div className="mb-5">
                  <div id="websiteAgreementsZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#websiteAgreementsTargetZero"
                      aria-expanded="true"
                      aria-controls="websiteAgreementsTargetZero">
                      <strong>Terms and Conditions</strong>
                    </h2>
                  </div>
                  <div
                    id="websiteAgreementsTargetZero"
                    className="collapse show"
                    aria-labelledby="websiteAgreementsZero"
                    data-parent="#websiteAgreements">
                    <p className="text-body mt-4 mb-6">
                      Pandev Law’s global reach and in-depth understanding of contract law
                      allows our IP lawyers to provide your business with well-drafted
                      terms and conditions tailored to your business needs. We focus on
                      reducing your liability while maximizing your returns from your web
                      presence. Our lawyers understand that the strength of your websites’
                      terms and conditions is the difference between your website
                      functioning as an endless funnel of new customers versus it being a
                      liability exposing you to lawsuits and enforcement actions.
                    </p>
                    <p className="text-body mb-6">
                      Our intellectual property legal services practice has deep
                      experience assessing the risk profile of e-commerce businesses, SaaS
                      providers, digital content creators and distributors, online media
                      publications, and many other web-based businesses. We draft
                      customized website terms and conditions, terms of use, and terms of
                      service agreements, among many others. Our attorneys help you
                      understand the legal risks associated with your online business
                      strategy and devise creative legal solutions to address your needs.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="websiteAgreementsOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#websiteAgreementsTargetOne"
                      aria-expanded="false"
                      aria-controls="websiteAgreementsTargetOne">
                      <strong>Privacy Policy</strong>
                    </h2>
                  </div>
                  <div
                    id="websiteAgreementsTargetOne"
                    className="collapse"
                    aria-labelledby="websiteAgreementsOne"
                    data-parent="#websiteAgreements">
                    <p className="text-body mt-4 mb-6">
                      A complex regulatory framework governs the collection, use, and
                      sharing of information about your website visitors. Jurisdictions
                      worldwide are increasingly implementing regulations mandating the
                      use of privacy policies that describe how and when website visitor
                      information is gathered, shared, and protected. The California
                      Consumer Privacy Act (CCPA) in the United States and the General
                      Data Protection Regulation (GDPR) of the European Union are just
                      some recent regulations that require website owners to have
                      detailed, clear, and conspicuous privacy policies.
                    </p>
                    <p className="mb-6">
                      Our attorneys’ international focus and years of internet and
                      technology law experience helps us craft website privacy policies
                      that are tailored to client needs and compliant with all relevant
                      regulations. Given Pandev Law’s focus on foreign companies entering
                      the US market, a niche practice of ours is advising on privacy
                      policy compliance for international brands with a newly established
                      US presence. We also regularly counsel US companies that are
                      expanding overseas and are exposed to overseas data privacy
                      regulations.
                    </p>
                    <p className="mb-6">
                      Given the severe penalties, including exorbitant fines, of
                      non-compliance with data privacy laws, it is critical that any
                      website owner establish compliant policies for gathering and storing
                      customer data. Our intellectual property legal services focus on
                      devising privacy policies that comply with relevant regulations
                      while also allowing you to continue to grow your online business
                      presence.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="websiteAgreementsTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#websiteAgreementsTargetTwo"
                      aria-expanded="false"
                      aria-controls="websiteAgreementsTargetTwo">
                      <strong>Internet Content Licensing</strong>
                    </h2>
                  </div>
                  <div
                    id="websiteAgreementsTargetTwo"
                    className="collapse"
                    aria-labelledby="websiteAgreementsTwo"
                    data-parent="#websiteAgreements">
                    <p className="text-body mt-4 mb-6">
                      Your business may republish or reuse published materials through a
                      content license, also known as a copyright license. Many websites
                      contain active tools, blogs, articles, videos, and other valuable
                      content created by the website owner. Some websites also contain
                      content initially posted on other websites and online platforms.
                      Regardless of whether you are the content creator or distributor of
                      someone else’s content, you are a party to a content licensing
                      agreement. This makes a content license a critical tool in
                      protecting your business from legal liability and in maximizing your
                      return from your online content.
                    </p>
                    <p className="mb-6">
                      An internet content license specifies which rights you grant to
                      users and the payment you expect in return for those rights. If you
                      reuse or republish content created by others, a content license
                      clarifies how you may use the content, ensures you receive all
                      rights you paid for, and protects you from lawsuits by the content
                      creator. Our intellectual property legal services lawyers take an
                      objective approach to drafting content license agreements, focusing
                      on including key terms that achieve your business goals. Our
                      intellectual property attorneys have experience with complex content
                      license agreements involving end-user created content. We focus on
                      minimizing your liability while maximizing your return from your
                      content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/intellectual-property/social-media.jpg"
                alt="Social media"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Social Media</h2>
              <p className="text-body mb-6">
                Pandev Law’s social media lawyers know how to successfully navigate the
                ever-changing social media landscape. We understand that clients need
                legal counsel with experience, international reach and an in-depth
                understanding of the terms and conditions of social media platforms. Our
                social media attorneys work with clients to develop effective social media
                compliance programs, whether it’s for a new business or an established
                global brand with an extensive social media presence. We use our
                intellectual property legal services, compliance, and internet law
                experience to represent clients in the following social media matters:
              </p>
              <ul className="list-bullets mb-6">
                <li>Social media platform compliance</li>
                <li>Social media regulatory compliance</li>
                <li>Social media intellectual property</li>
                <li>Social media affiliate advertising</li>
                <li>Social media endorsements</li>
              </ul>
              <p className="text-body mb-6">
                Pandev Law’s social media attorneys have a deep understanding of the terms
                of use of Youtube, Twitter, Facebook, Instagram, Tiktok, and other social
                media platforms. We understand the importance of quickly capitalizing on
                new social media platform features. Our social media lawyers keep
                up-to-date with platform terms and conditions and are able to provide
                effective legal advice as soon as new advertising features are rolled out.
                Social media activity has been in regulators' crosshairs for a while –
                whether it’s the Federal Trade Commission (FTC) or Securities Exchange
                Commission (SEC). Our principal attorney’s experience as Trial Attorney at
                the US Department of Justice in Washington, DC provides us with the
                handson regulatory background to assist clients in identifying and
                mitigating regulatory risks of social media campaigns.
              </p>
              <p className="text-body mb-6">
                Our social media lawyers use their intellectual property legal services
                experience to provide strategic guidance on how to share, repost, and
                republish content created by others without infringing on trademark,
                copyright, and other IP rights. Influencer, endorser, and affiliate
                advertising strategies are critical to successful social media campaigns.
                Our social media attorneys perform extensive due diligence before clients
                engage influencers and affiliates. We draft influencer and affiliate
                agreements that ensure their performance and a return on your campaign.
                Our international capability puts us at an advantage in drafting
                cross-border social media service agreements, the importance of which has
                exploded as social media became the main driver of cross-border e-commerce
                sales. Our clients’ record of tremendous social media success speaks to
                the quality and effectiveness of our legal representation.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/intellectual-property/trademark-and-copyright-prosecution.jpg"
                alt="Trademark & Copyright Prosecution"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Trademark &amp; Copyright Prosecution
              </h2>
              <p className="text-body mb-5">
                Pandev Law’s intellectual property legal services practice is dedicated to
                protecting your brand and original work through comprehensive trademark
                and copyright filing and prosecution representation. We understand the
                importance of brand protection before entering the US market. Our IP
                lawyers develop comprehensive brand protection and trademark prosecution
                strategies for foreign companies expanding to the United States. We advise
                international clients with multinational trademark portfolios on
                protecting their brands in the United States and provide representation
                before the US Patent and Trademark Office (USPTO). Our experienced
                trademark lawyers use our firm’s extensive global network to help US
                companies protect their brands as they scale their overseas operations.
              </p>
              <p className="text-body mb-5">
                The international nature of our practice makes us uniquely capable of
                representing foreign creators in protecting original works. Our copyright
                lawyers obtain copyright registrations for software, websites, books,
                video games, art work, and many other original works. We represent foreign
                creators before the US Copyright Office and file registrations with the
                Electronic Copyright Office (eCO). Our attorneys rely on their
                intellectual property experience to advise on complex copyright matters
                and documentation requirements to protect clients from ownership disputes
                involving employees and independent contractors.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/intellectual-property/cybersecurity-privacy-and-data-protection.jpg"
                alt="Cybersecurity, Privacy, and Data Protection"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                Cybersecurity, Privacy, and Data Protection
              </h2>
              <p className="text-body mb-6">
                Cybersecurity and data protection are widely regarded as the top risk
                management concern of the 21st century. Cyber attacks and data breaches
                only continue to increase as companys’ dependence on network applications
                and digital technologies grows. The multinational nature of work in the
                digital age, with companies having international offices and outsourced
                service providers, has only exacerbated the risks of data and privacy
                protection. Businesses are constantly wondering how to balance the need to
                increase efficiency through technology while also safeguarding
                confidential, proprietary and personal information. As part of our
                intellectual property legal services, our attorneys craft comprehensive,
                real-world legal strategies that mitigate the risks of privacy and data
                security. Our services include:
              </p>
              <ul className="list-bullets mb-6">
                <li>Review existing enterprise-wide data policies</li>
                <li>Develop and implement new privacy policies</li>
                <li>Outsourcing agreements</li>
                <li>Cloud computing agreements</li>
                <li>Professional services agreements</li>
                <li>Software license agreements</li>
                <li>Data breach crisis counseling</li>
              </ul>
              <p className="text-body mb-6">
                Pandev Law’s attorneys draw on their broad experience in intellectual
                property, regulatory compliance, and cross-border transactions to protect
                against the data and privacy risks involved in working with outside
                service providers. Our depth and breadth of experience in international
                transactions allows us to draft comprehensive international vendor and
                outsourcing agreements that mitigate liability by explicitly specifying
                data and information protection requirements. We provide strategic advice
                on how to comply with state, federal, and international privacy and data
                protection laws and regulations, including the Health Insurance
                Portability and Accountability Act (HIPAA), California Consumer Privacy
                Act (CCPA), and the EU General Data Protection Regulation (GDPR).
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Intellectual Property Client Reviews"
        testimonials={INTELLECTUAL_PROPERTY_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default IntellectualProperty;

export const query = graphql`
  query IntellectualPropertyQuery {
    category: wpCategory(slug: { eq: "intellectual-property-law" }) {
      ...BlogLandingHeroPost
    }
  }
`;
