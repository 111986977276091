import React from 'react';
import { resolveBlogCategorySlug } from '../../utils';
import FocusCategory from './Focus-category';
import PostsSlider from '../common/Posts-slider';

function CategorySlider({ category, index }) {
  const { slug, name, databaseId, posts } = category;
  const correctSlug = decodeURIComponent(slug);

  if (index % 2 === 0) {
    const trimmedPosts = { nodes: posts.nodes.slice(0, 9) };
    return (
      <FocusCategory
        id={databaseId}
        posts={trimmedPosts}
        title={name}
        slug={correctSlug}
        reversed={index % 4 !== 0}
      />
    );
  }

  const trimmedPosts = {
    edges: posts.nodes.slice(0, 9).map((post) => ({
      node: post,
    })),
  };

  return (
    <div className="section-pale pt-5 pb-5" id={databaseId}>
      <PostsSlider
        title={name}
        posts={trimmedPosts}
        seeMoreLink={resolveBlogCategorySlug(correctSlug)}
        seeMoreText="See more"
      />
    </div>
  );
}

CategorySlider.propTypes = {};

export default CategorySlider;
