export const INTELLECTUAL_PROPERTY_TESTIMONIALS = [
	{
		text: "Pandev Law is an exceptionally professional legal team that go above-and-beyond for their clients. Working personally with Adrian and Michael, I can say with certainty that Pandev Law is a legal team with exceptional knowledge of the industry, as they offer their clients trustworthy legal council and recommendations based on their experience. I'm appreciative of their services and am writing this review to say that if you are considering working with Pandev Law, you are already in good hands.",
		name: 'Jake Crossman',
		stars: 5,
	},
	{
		text: 'I am extremely satisfied with my experience working with Pandev Law firm. Attorney Michael P. Hayes handled my case; he provided me with outstanding support and made the whole journey very smooth. Throughout the entire process, he handled each step with the highest level of professionalism. I really enjoyed working with Michael from the first meeting till the end of the process. I highly recommend him and the other attorneys at this firm!',
		name: 'George Darouni',
		stars: 5,
	},
	{
		text: 'Working with Adrian was nothing short of an excellent experience. His attention to detail and quick responses to questions is couple of the many great qualities about him.\nI would highly recommend him to anyone needing his professional expertise.',
		name: 'Pank Vij',
		stars: 5,
	},
	{
		text: "Adrian Pandev is a great lawyer. He is one of those rare lawyers that doe not only work for money. He really cares about people and helping them. If you are desperate and do not know what do just contact him you'll see for yourself",
		name: 'Shafagh K',
		stars: 5,
	},
	{
		text: "It's a great pleasure working with Pandev Law - very professional and knowledgeable. I highly recommend them.",
		name: 'Petya Gospodinova',
		stars: 5,
	},
	{
		text: 'Adrian is a knowledgeable and professional lawyer who makes time for his clients and offer them the best advice. I highly recommend his professional services.',
		name: 'Shoaib Iqbal',
		stars: 5,
	},
	{
		text: "Just the nicest attorney I've met. Very straightforward and makes every effort to help his client. Would recommend 10/10.",
		name: 'Dinesh Arjun',
		stars: 5,
	},
	{
		text: 'These guys are on the ball, attentive, fast, professional. Give them a call.',
		name: "Mark O'Shea",
		stars: 5,
	},
	{
		text: 'Adrian did a great job. I was very happy with his work and he was very thorough. I highly recommend him.',
		name: 'Jordan Slotnick',
		stars: 5,
	},
];
