import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, useStaticQuery } from 'gatsby';
import classnames from 'classnames';
import { createBlogLink, resolveBlogCategorySlug } from '../../utils';
import CommonCard from '../common/common-card';

function FocusCategory(props) {
  const data = useStaticQuery(graphql`
    query BlogFocusCategoryFallbackImage {
      fallbackImage: file(name: { regex: "/statue-liberty/" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const { posts, title, slug, reversed } = props;

  const focusPosts = posts.nodes.slice(0, 2);

  const normalPosts = posts.nodes.slice(2, 5);

  return (
    <div className="container my-5 py-5">
      <h3 className="text-mega mb-5">{title}</h3>

      <div className="row">
        <div className={classnames('col-lg-8', { 'order-lg-1': reversed })}>
          <div className="row">
            {focusPosts.map((edge) => {
              const { id, title: postTitle, date, slug, excerpt, featuredImage } = edge;

              return (
                <CommonCard
                  key={id}
                  title={postTitle}
                  description={excerpt}
                  link={createBlogLink(slug)}
                  date={date}
                  imgSrc={featuredImage?.node?.localFile || data.fallbackImage}
                  className="col-lg-6 mb-5"
                  hasReadMoreButton
                />
              );
            })}
          </div>
        </div>

        <div
          className={classnames('col-lg-4 mt-5 mt-lg-0', {
            'order-lg-0': reversed,
          })}>
          <div className="d-block w-100">
            {normalPosts.map((edge) => {
              const { id, title: postTitle, date, slug, excerpt } = edge;

              return (
                <CommonCard
                  key={id}
                  title={postTitle}
                  link={createBlogLink(slug)}
                  description={excerpt}
                  date={date}
                  className="col-12 px-0 mb-5"
                  smallTitle
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="text-right mt-6">
        <Link to={resolveBlogCategorySlug(slug)}>
          <span>See more</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-arrow-right pl-3 mb-3"
            viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}

FocusCategory.propTypes = {
  posts: PropTypes.object,
  title: PropTypes.string,
  slug: PropTypes.string,
  reversed: PropTypes.bool,
};

export default FocusCategory;
